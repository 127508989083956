<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="appLogoImageName"
                    alt="logo"
                    style="height: 80px; width: 150px;"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        {{ $t('login.welcome_to_imprion') }} 👋
                    </b-card-title>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent
                        >
                            <!-- email -->
                            <b-form-group
                                :label="this.$t('form_data.email')"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="this.$t('form_data.email')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="email"
                                        :state="errors.length > 0 ? false:null"
                                        name="login-email"
                                        placeholder="john"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group :label="$t('general.password')">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="this.$t('general.password')"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false:null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <div class="d-flex justify-content-between">
                                <b-form-group>
                                    <b-form-checkbox
                                        id="remember-me"
                                        v-model="rememberMe"
                                        name="checkbox-1"
                                    >
                                        {{$t('login.remember_me')}}
                                    </b-form-checkbox>
                                </b-form-group>

                                <b-link @click="$router.push({name: 'forgotten-password'})">
                                    {{$t('password.forgotten_password')}}?
                                </b-link>
                            </div>


                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                {{$t('login.log')}}
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { $themeConfig } from '@themeConfig'

    import {
        BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BImg, BForm, BButton
    } from 'bootstrap-vue'
    import { required } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import Vue from 'vue'

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                password: '',
                email: '',
                rememberMe: false,
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required
            }
        },
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },
        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(() => {
                    this.loginJWT()
                })
            },
            async loginJWT() {
                this.$store.state.app.showLoader = true

                const payload = {
                    email: this.email,
                    password: this.password,
                    remember_me: this.rememberMe
                }
                const thisIns = this

                try {
                    const loginResponse = await this.$http.post('/api/client/v1/login/', payload)
                    await this.$store.dispatch('user/setLoginStatus', true)
                    await this.$store.dispatch('user/updateUserData', loginResponse.data)
                    await this.$store.dispatch('setup/getSetupProgress')
                    localStorage.setItem('userData', JSON.stringify(loginResponse.data))

                    const res = await this.$http.get('/api/client/v1/users/permissions')
                    const permissions = res.data ?? []

                    const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
                    const clientData = res1.data ?? {}

                    await this.$store.dispatch('client/setClientData', clientData)
                    await this.$store.dispatch('user/setUserPermissions', permissions)
                    this.$nextTick(function() {
                        thisIns.$router.push('/')
                    })

                    this.$store.dispatch('messages/set_messages')
                    this.$store.dispatch('messages/set_unread_urgent_messages')
                } catch (err) {
                    if (localStorage.getItem('progress') === '0') {
                        await thisIns.$router.push('/installation-wizard')
                    } else if (err.response.status === 401 && thisIns.$router.currentRoute.name !== 'home') {
                        this.$printWarning(this.$t('login.wrong_password'))
                        await thisIns.$router.push('/')
                    } else if (err.response.status === 403) {
                        if (err.response.data === 'user no client') {
                            this.$store.dispatch('user/setUserNoClients')
                        } else if (err.response.data === 'user missing client') {
                            this.$store.dispatch('user/selectFirstClient')
                        }
                    }
                } finally {
                    this.$store.state.app.showLoader = false
                }
            }

        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
